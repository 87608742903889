$dark: #111111;
$text: #d9d9d9;
//$dark: #d9d9d9;
//$text: #111111;
$accent: #ffb700;
body {
  background: $dark;
  color: $text;
  font-family: "Inter", sans-serif;
  overflow-x: hidden;
}

.accent {
  color: $accent;
}

body {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */

  //Hiding scroll bar in the website in IE, Edge, Firefox
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: scroll;
}

//Hiding scroll bar in the website in Chrome, Safari, and Opera
body::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

a {
  text-decoration: none;
  color: $accent !important;
}
.footer {
  a {
    color: $dark !important;
  }
}

.App {
  overflow-x: hidden;
  ul {
    padding-left: 0;
    li {
      list-style: none;
    }
  }
}

.title {
  p {
    font-style: italic;
    font-weight: 400;
  }
  font-style: normal;
  font-weight: 900;
  font-size: 128px;
  line-height: 88.52%;
  letter-spacing: -0.015em;
  @media only screen and (max-width: 600px) {
    font-size: 45px;
  }
}

.subtitle {
  font-weight: 700;
  font-size: 40px;
  line-height: 88.52%;
  margin-bottom: 51px;
  letter-spacing: -0.015em;
  text-transform: uppercase;
  @media only screen and (max-width: 600px) {
    font-size: 32px;
    margin-bottom: 31px;
  }
}

.text {
  font-weight: 400;
  font-size: 14px;
  line-height: 113.52%;
  @media only screen and (max-width: 600px) {
    font-size: 15px;
    line-height: 123.52%;
  }
}

.University {
  .title {
    padding: 4% 10% 0px 10%;
    p {
      font-size: 32px;
      padding-top: 12px;
      line-height: normal;
      @media only screen and (max-width: 600px) {
        font-size: 24px;
      }
      .circle {
        background: none;
        border: 2px solid $accent;
        height: 39px;
        width: 39px;
        border-radius: 50%;
        cursor: pointer;
      }
      .pulsating {
        &:before {
          background-color: $accent;
        }
      }
    }
    .btn {
      background: none;
      &:hover {
        color: $text !important;
      }
    }
    line-height: 113.52%;
    @media only screen and (max-width: 600px) {
      margin-bottom: 15%;
      line-height: 103.52%;
    }
  }
  .subtitle {
    margin-bottom: 31px;
  }
  .projects {
    min-height: 50vh;
  }
  .circle {
    border-radius: 22px !important;
  }
  .circle:hover {
    border-radius: 50% !important;
  }
  .pulsating {
    &:before {
      border-radius: 22px;
    }
  }
}
.Home {
  .btn {
    background: none;
    border: none;
    &:hover {
      background: none;
      border: none;
      color: $text !important;
    }
  }
}
.hero {
  min-height: 50vh;
  padding: 7% 10% 0px 10%;
  svg {
    height: 90%;
  }
  @media only screen and (max-width: 600px) {
    min-height: 50vh;
    svg {
      width: 80%;
      margin-bottom: 20px;
    }
  }
}
.aboutme {
  min-height: 50vh;
  padding: 0px 10% 0px 10%;
  img {
    height: 400px;
    @media only screen and (max-width: 600px) {
      height: 50%;
    }
  }
  .circle {
    background: $text;
    height: 159px;
    width: 159px;
    border-radius: 50%;
    margin-top: -45%;
    cursor: pointer;
  }
}
.projects {
  min-height: 80vh;
  padding: 0px 10% 0px 10%;
  .col {
    padding: 20px;
    margin: 14px;
    @media only screen and (max-width: 600px) {
      border: 1px solid $text;
    }
  }

  .project-light {
    background: $text;
    border-radius: 22px;
    color: $dark;
    .circle {
      margin-top: 26px;
      background: $dark;
      height: 159px;
      width: 159px;
      border-radius: 50%;
      margin-bottom: 10px;
      cursor: pointer;
    }
  }

  .project-dark {
    background: $dark;
    border-radius: 22px;
    color: $text;
    .circle {
      margin-top: 26px;
      background-color: $text;
      height: 159px;
      width: 159px;
      border-radius: 50%;
      margin-bottom: 10px;
      cursor: pointer;
    }
  }
  .circle:hover {
    border-radius: 22px;
    transition: 300ms;
    .circle {
      background-color: $dark;
    }
  }
}

.uni-projects {
  cursor: pointer;
  min-height: 20vh;
  margin: 5% 10% 10% 10%;
  background: $dark;
  border-radius: 22px;
  border: 1px solid $text;
  color: $text;
  p {
    width: 60%;
    @media only screen and (max-width: 600px) {
      width: 80%;
    }
  }
  .circle {
    background: $accent;
    height: 69px;
    width: 69px;
    border-radius: 50%;
    margin-right: -5px;
    cursor: pointer;
    @media only screen and (max-width: 600px) {
      height: 49px;
      width: 49px;
    }
  }
}

.contact {
  min-height: 41vh;
  margin: 5% 10% 10% 10%;
  background: $dark;
  .title {
    p {
      font-style: italic;
      font-weight: 400;
    }
    font-style: normal;
    font-weight: 900;
    font-size: 108px;
    line-height: 88.52%;
    letter-spacing: -0.015em;
    @media only screen and (max-width: 600px) {
      font-size: 46px;
    }
  }
  .circle {
    background: $text;
    height: 99px;
    width: 99px;
    border-radius: 50%;
    margin: 10px;
    @media only screen and (max-width: 600px) {
      height: 49px;
      width: 49px;
    }
  }
}

.footer {
  content: "";
  min-height: 25vh;
  width: 100%;
  background: $text;
  color: $dark;
  .separator {
    content: "";
    background: $dark;
    border-radius: 0 0 22px 22px;
    height: 40px;
    margin-top: -2px;
  }
  .row {
    img {
      height: 35px;
      margin-top: -6px;
      margin-right: 10px;
    }
    margin: 4% 10% 4% 10%;
    @media only screen and (max-width: 600px) {
      margin-top: 20%;
      margin-bottom: 20%;
    }
    .row {
      margin: 0;
      @media only screen and (max-width: 600px) {
        margin-top: 20%;
      }
      .col {
        padding: 0;
      }
    }
    .subtitle {
      font-weight: 900;
      font-size: 24px;
      margin-bottom: 31px;
      text-transform: lowercase;
      @media only screen and (max-width: 600px) {
        font-size: 22px;
        margin-bottom: 21px;
      }
    }
  }
}

.logodiv {
  width: 358px;
  height: 358px;
  background: $text;
  border-radius: 22px;
  div {
    width: 268px;
    height: 268px;
    position: relative;
    top: 30%;
    left: 30%;
    border-radius: 50%;

    background: $dark;
  }
}

.pulsating {
  position: relative;
  &:before {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    box-sizing: border-box;

    border-radius: 50%;
    background-color: #ffffff;
    animation: pulse-ring 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
  }
}
.pulsating:hover {
  &:before {
    content: none;
    animation: none;
  }
}
@keyframes pulse-ring {
  0% {
    transform: scale(0.33);
  }
  80%,
  100% {
    opacity: 0;
  }
}

.faded {
  position: relative;
  &:before {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    box-sizing: border-box;

    border-radius: 50%;
    background-color: $text;
    opacity: 0.3;
    transform: translateY(50px);
  }
}

//svg animation
.path {
  stroke-width: 2;
  stroke: $accent;
  fill: transparent;
  animation: dash 600ms linear;
  animation-fill-mode: forwards;
}
.second {
  animation-delay: 300ms;
}
@keyframes dash {
  to {
    stroke-dashoffset: 0;
  }
}
.rect {
  animation: drawing 500ms linear 1.05s;
  animation-fill-mode: forwards;
}
@keyframes drawing {
  to {
    fill: $text;
  }
}
.logo-circle {
  fill: $dark;
}
